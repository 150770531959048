<template lang="">
<div class="promo-paper-wrap">
  <div class="promo-paper" v-if="getSettings && getSettings.blyz_sales_group">
    <div class="img-wrap" v-if="getSettings.blyz_sales_group.blyz_img_paper">
      <router-link
        v-if="
          getSettings &&
          getSettings.blyz_add_group_links &&
          getSettings.blyz_add_group_links.blyz_link_papper
        "
        :to="link(getSettings.blyz_add_group_links.blyz_link_papper)"
        class="img"
        >
        <img
        :src="getSettings.blyz_sales_group.blyz_img_paper.sizes.large"
        :alt="getSettings.blyz_sales_group.blyz_title_paper"
      />
      <div id="fpc_corner-box" v-if="getSettings.blyz_sales_group.blyz_img_paper_page">
          <a id="fpc_page-tip" href="#">
      <div id="fpc_corner-contents"><div id="fpc_corner-button" :style="`background: url('${getSettings.blyz_sales_group.blyz_img_paper_page.sizes.large}')`">
      </div></div></a>
      </div>
</router-link>
    </div>
    <div class="content" v-if="getSettings.blyz_sales_group">
      <h2 v-if="showTitle && getSettings.blyz_sales_group.blyz_title_paper" class="title">
        {{ getSettings.blyz_sales_group.blyz_title_paper }}
      </h2>

      <div class="style-text-wrap" v-if="getSettings.blyz_sales_group">
        <p class="style-text color-text" v-if="getSettings.blyz_sales_group.blyz_call_text_1" >{{ getSettings.blyz_sales_group.blyz_call_text_1}}</p>
      </div>
      <div class="style-text-wrap" v-if="getSettings.blyz_sales_group">
        <p class="style-text color-text" v-if="getSettings.blyz_sales_group.blyz_call_text_2" >{{ getSettings.blyz_sales_group.blyz_call_text_2}}</p>
      </div>
      <router-link
        v-if="
          getSettings &&
          getSettings.blyz_add_group_links &&
          getSettings.blyz_add_group_links.blyz_link_papper
        "
        :to="link(getSettings.blyz_add_group_links.blyz_link_papper)"
        class="btn"
        >{{ getSettings.blyz_sales_group.blyz_text_btn_paper }}</router-link
      >
    </div>
  </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { computed } from 'vue';
export default {
    props: {
        showTitle: Boolean
    },
    computed: {
        ...mapGetters(["getSettings"]),
    },
    methods: {
        link(url) {
            const baseUrl = window.location.origin + "/blyzenko/";

            const link = new URL(url, baseUrl);
            if (link.pathname) {
                const url = link.hash ? link.pathname + link.hash : link.pathname;
                return url;
            }

            return link;
        },
    },

};
</script>
<style lang="scss">

</style>
