<template>
  <div class="form-wrap mainForm">
    <form
      class="wpcf7-form"
      name="landingForm"
      @submit.prevent="sendFormFeedBack"
    >
      <input hidden id="shop_id" :value="selectedShopId" />
      <input hidden id="type" :value="formData.type" />
      <input hidden id="shop-addres-select" :value="selectedShopAddress" />
      <p>
        <Multiselect
          v-if="!type"
          id="reasonSelect"
          placeholder="Причина звернення"
          v-model="formData.type"
          :options="reason"
         :close-on-select="true"
         :required="true"
        />
      </p>
      <p><input type="text" id="name" placeholder="І'мя"  v-model="formData.name"/></p>
      <p><input type="tel" id="tel-number" placeholder="Телефон" v-model="formData.telNumber" /></p>
      <p>
        <input type="email" id="user-email" placeholder="Електронна пошта" v-model="formData.userEmail"/>
      </p>
      <p class="shop-address-wrap">
        <Multiselect
          placeholder="Магазин"
          v-model="selectedShop"
          :options="options"
           label="name"
           :searchable="true"
           :close-on-select="true"
           @select="selectAddres"
        />
      </p>
      <p>
        <textarea
          type="text"
          id="message"
          rows="4"
          placeholder="Подробиці"
          v-model="formData.message"
        ></textarea>
      </p>
      <p><input type="submit" value="Надіслати" /></p>
      <p class="status error" v-if="sendError">{{ sendError }}</p>
      <p class="status" v-if="sendResult">{{ sendResult }}</p>
    </form>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    type: String,

  },
  components: {
    Multiselect,
  },
  data() {
    return {
      options: [],
      selectedShop: null,
      reason: ['Подяка', 'Скарга', 'Відгук про каву', 'Інше'],
      reasonSelected: null,
      selectedShopId: null,
      selectedShopAddress: null,
      formData: {
          shopId: this.selectedShopId,
          type: this.reasonSelected,
          shopAddress: '',
          name: '',
          telNumber: '',
          userEmail: '',
          message: ''
      },
      sendResult: '',
      sendError: ''
    };
  },
  computed: {
    ...mapGetters(["getShops", "getSendFormError", "getSendFormConfirm"]),
  },
  mounted() {
    this.loadShops();
    this.loadJsFile('script')
  },
  beforeUnmount() {
    const scriptId = `script-js`;
    const scriptLink = document.getElementById(scriptId);

    if (scriptLink) {
      document.head.removeChild(scriptLink);
    }
  },
  reasonSelectSettings(){
    return {

    }
  },
  methods: {
     ...mapActions(["fetchShops", "sendForm"]),
    async loadShops() {
        await this.fetchShops()
        .then(() => {
          this.getShops?.every((shop) => {
            const shopAddress = shop.address;

            let id = `${shopAddress} id:${shop.acf_fields?.shop_id}`;
            let address = {
              value: id,
              name: shopAddress,
            };

              return this.options.push(address);
          });

        });
    },
    
    selectAddres(){
      if(!this.selectedShop) {
        this.selectedShopId = null
        this.selectedShopAddress = null
        return
      }

      this.selectedShopId = this.selectedShop.match(/id:(\d+)/)[1]
      this.formData.shopId = this.selectedShopId
      this.selectedShopAddress = this.selectedShop
      this.formData.shopAddress = this.selectedShopAddress

    },
    loadJsFile(fileName) {
            if (!fileName || document.getElementById(fileName)) return;

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement ? domainElement.content : "https://blyzenko.ua";

            const url = `${domain}/wp-content/themes/blyzenko-vue/${fileName}.js`;

            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = `${fileName}-js`;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds) 
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        },
        async sendFormFeedBack(){
            await this.sendForm(this.formData)
            .then(()=> {
              this.sendResult = this.getSendFormConfirm?.message
              this.sendError = this.getSendFormError?.message
            })
        }
  },
};
</script>
<style lang="css">
.form-wrap {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;

}

</style>
<style src="@vueform/multiselect/themes/default.css"></style>
