<template lang="">
  <article v-if="page">
    <div
      v-if="page.content && page.content.rendered"
      v-html="pageContent"
      ref="contentContainer"
    ></div>
        
    <section class="vacances-section" id="vacances-list">
      <div class="container">
        <h2>Підібрати вакансію</h2>
        <div class="vacances">
          <div class="vacances-filtres">
          <div class="vacances-filtres-main">
            <div class="col">
              <Multiselect
                placeholder="Місто"
                v-model="citySelected"
                :options="cityList"
                label="name"
                @select="filterVacancies"
                @clear="filterVacancies"
              />
            </div>
            <transition name="fade-down">
              <div class="col mob" v-if="cityRegions">
                <Multiselect
                    v-if="cityRegions"
                    placeholder="Район"
                    v-model="regionSelected"
                    :options="cityRegions"
                    label="name"
                    @select="filterVacancies"
                    @clear="filterVacancies"
                  />
              </div>
            </transition>
            <div class="col">
              <Multiselect
                v-if="departments"
                placeholder="Підрозділ"
                v-model="departmentsSelecter"
                :options="departments"
                label="name"
                :close-on-select="true"
                @select="filterVacancies"
                @clear="filterVacancies"
              ></Multiselect>
            </div>
            <div class="col">
              <Multiselect
                v-if="workType"
                placeholder="Посада"
                v-model="workTypeSelected"
                :options="workType"
                :close-on-select="true"
                @select="filterVacancies"
                @clear="filterVacancies"
              />
            </div>
            
            <div class="col">
              <Multiselect
                v-if="vacancesWorkDate"
                placeholder="Зайнятість"
                v-model="WorkDateSelected"
                :options="vacancesWorkDate"
                :close-on-select="true"
                @select="filterVacancies"
                @clear="filterVacancies"
              ></Multiselect>
            </div>
          </div>
            
            <transition name="fade-down">
              <div class="vacances-filtres-additional"   v-if="cityRegions">
                  <div class="col">
                    <Multiselect
                        v-if="cityRegions"
                        placeholder="Район"
                        v-model="regionSelected"
                        :options="cityRegions"
                        label="name"
                        @select="filterVacancies"
                        @clear="filterVacancies"
                      />
                  </div>
                  
              </div>
            </transition>
          </div>
          <div class="vacances-list-wrap">
            <ul class="vacances-list">
              <li class="vacancy-item" v-for="item in vacancesList" :key="item">
                <a :href="item.url ? link(item.url) : '#'" class="animate-box-hover">
                  <div class="row">
                    <div class="col content">
                      
                      <p class="title">{{ vacancyName(item) }}</p>
                      <address class="city" v-if="item.location">
                        <span v-for="(city, index) in item.location" :key="city">{{city.term_name}}<span v-if="index == 0 && item.location.length > 1">, </span></span>
                      </address>
                    </div>
                    <div class="col icon-block">
                      <span class="icon"><svg version="1.1" width="72" height="56" style="
"><path d="M0 0 C5.43733055 4.52991796 10.46458931 9.34294216 15.4375 14.375 C16.53481445 15.47908203 16.53481445 15.47908203 17.65429688 16.60546875 C19.43852723 18.40136074 21.22036124 20.19955985 23 22 C18.47008204 27.43733055 13.65705784 32.46458931 8.625 37.4375 C7.52091797 38.53481445 7.52091797 38.53481445 6.39453125 39.65429688 C4.59863926 41.43852723 2.80044015 43.22036124 1 45 C-0.65 43.35 -2.3 41.7 -4 40 C0.29 35.71 4.58 31.42 9 27 C-12.78 26.505 -12.78 26.505 -35 26 C-35 23.69 -35 21.38 -35 19 C-20.48 18.67 -5.96 18.34 9 18 C4.71 13.71 0.42 9.42 -4 5 C-2.68 3.35 -1.36 1.7 0 0 Z " fill="#F7FBF6" transform="translate(43,5)"></path></svg>
</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="counter-section">
      <div class="container">
        <div class="counter-block" v-if="pageFields && pageFields.counters">

          <SingleCounter
            v-for="count in pageFields.counters"
            :key="count"
            :count="count.count"
            :title="count.title"
          />
        </div>
      </div>
    </section>
    <section class="form-sectio" v-if="pageFields">
      <div class="container">
        <div class="row">
          <div class="col">
            <h3 class="title" v-if="pageFields && pageFields.title_vacancy_call">{{pageFields.title_vacancy_call}}</h3>
            <p class="text" v-if="pageFields && pageFields.text_vacancy_call">{{pageFields.text_vacancy_call}}</p>
          </div>
          <div class="col">
            <button class="btn show-form" v-if="pageFields && pageFields.text_vacancy_call" @click="modalForm">НАДІСЛАТИ РЕЗЮМЕ</button>
          </div>
        </div>
      </div>
    </section>

    <section class="section-team" v-if="pageFields && pageFields.image_team">
      <div class="container">
        <picture class="img" v-if="pageFields && pageFields.image_team && pageFields.image_team.sizes">
          <img :src="pageFields.image_team.sizes['2048x2048']" :alt="pageFields.image_team.name">
        </picture>
      </div>
    </section>
  </article>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";
import SingleCounter from '../global/SingleCounter.vue';
import { gsapMixin } from '../../mixins/gsapMixin';
import { Vue3Lottie } from 'vue3-lottie'
import planJSON from '@/assets/plane_animate.json';
import { ref, onMounted, nextTick } from 'vue';
import { gapi } from 'gapi-script';

export default {
  mixins: [gsapMixin],
  components: {
    Multiselect,
    SingleCounter,
    Vue3Lottie,
  },

  setup() {
    const contentContainer = ref(null);

    function executeInlineScripts() {
  if (contentContainer.value) {
    const scripts = contentContainer.value.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      newScript.textContent = script.textContent;
      document.body.appendChild(newScript);
      // Clean up: remove the script element after execution
      document.body.removeChild(newScript);
    });
  } else {
    console.warn('contentContainer ref is not available');
  }
}

// Fetch content and execute scripts when the component is mounted
onMounted(async () => {
  await nextTick(); // Ensure DOM updates are complete
  executeInlineScripts();
});


    return {
      contentContainer
    }
  },

  data() {
    return {
      page: null,
      pageId: null,
      pageFields: null,
      vacancesList: null,
      vacancesWorkDate: null,
      WorkDateSelected: null,
      workType: null,
      workTypeSelected: null,
      cityList: null,
      cityRegions: null,
      citySelected: null,
      regionSelected: null,
      departments: null,
      departmentsSelecter: null,
      animateJson: null,
      pageContent: '',
      contactForm: null,
      file: null
    };
  },
  computed: {
    ...mapGetters([
      "getPage",
      "getSettings",
      "getVacances",
      "getVacancesWorkDate",
      "getVacancesCityList",
      "getVacancesCityRegion",
      "getVacancesWorkType",
      "getVacancesDepartments",
      "getFormHtml",
      "getFormFileId",
      "getFormFileError",
      "getSendFormError"
    ]),
  },
  beforeUnmount() {
    const stylesheetId = `elementor-post-${this.pageId}-css`;
    const stylesheetLink = document.getElementById(stylesheetId);

    if (stylesheetLink) {
      document.head.removeChild(stylesheetLink);
    }
    const fileLink = document.getElementById('js-script');
    if (fileLink) {
        document.head.removeChild(fileLink);
    }
  },
  mounted() {
    const pageSlug = this.$route.params?.pageSlug;
    this.loadPageContent(pageSlug);
    this.loadVacancesList();
   this.animateJson = planJSON
   this.loadJsFile('script')
  },
  methods: {
    ...mapActions(["fetchVacances", "fetchPage", "fetchContactForm", "sendContactFormForm", "fetchUploadFile"]),

    loadPageContent(slug) {
      if(this.getPage[0]) {
        this.page = this.getPage[0];
        this.pageId = this.page.id;
        this.pageFields = this.page.acf_fields;
        this.pageContent = this.page.content.rendered;
        this.loadCssFile();
        this.fetchContactForm(this.pageFields.vacancy_form_shortcode)
        .then(()=>{
          this.contactForm = this.getFormHtml ? this.getFormHtml.html : null
        })
        return
      }
      this.fetchPage(slug).then(() => {
        this.page = this.getPage[0];
        this.pageId = this.page.id;
        this.pageFields = this.page.acf_fields;
        this.loadCssFile();
      });
      
    },

    loadVacancesList() {
      this.fetchVacances().then(() => {
        this.vacancesList = this.getVacances;
        this.cityList = this.getVacancesCityList
        this.vacancesWorkDate = this.getVacancesWorkDate.map((item) => item.name)
        this.workType = this.getVacancesWorkType.map((item) => item.name)
        this.departments = this.getVacancesDepartments ? this.getVacancesDepartments : {}

        const proxyObject = new Proxy(this.departments, {});
        this.departments = Object.entries(proxyObject).map(([key, value]) => ({
          name: value,
          value: key
        }));

        if(this.$route.query.type) {
          const typeTaxonomy = this.getVacancesWorkType.filter((item)=> item.slug == this.$route.query.type)

          this.workTypeSelected = typeTaxonomy && typeTaxonomy.length ? typeTaxonomy[0].name : null

          const vacancySection = document.querySelector('#vacances-list');

          const elTop = vacancySection?.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({top: elTop - 50, behavior: "smooth"});
        }
      });
    },
    filterVacancies() {
      
      this.cityRegionsActive(this.citySelected)

      if (!this.workTypeSelected && !this.WorkDateSelected && !this.citySelected && !this.departmentsSelecter) {
        this.vacancesList = this.getVacances;
        this.regionSelected = null
        return;
      }

      this.vacancesList = this.getVacances.filter((item) => {
        return (
          (this.isWorkDate(item.vacancy_type, this.workTypeSelected) || !this.workTypeSelected) &&
          (this.isWorkDate(item.work_date, this.WorkDateSelected) || !this.WorkDateSelected) &&
          (!this.departmentsSelecter ||  item?.acf_fields?.department == this.departmentsSelecter) &&
          ((!this.citySelected || (item.location && this.hasLocation(item.location, this.citySelected))) &&
          (!this.regionSelected || (item.location && this.hasLocation(item.location, this.regionSelected))))
        );
      });
    },

    hasLocation(locations, id){
      const findLocation = locations.filter(item => item.term_id === id)
      return findLocation.length ? true : false
    },
    cityRegionsActive(cityId) {

      if(!cityId) {
        this.cityRegions = null
        return null
      }     

      this.cityRegions = this.getVacancesCityRegion.filter((location) => location.parent == cityId)

      this.cityRegions = this.cityRegions.length ? this.cityRegions : null

      this.regionSelected = !this.cityRegions ? null : this.regionSelected

      return this.cityRegions
    },
    isWorkDate(obj, item) {
      if (!item) return false;  // Return false if item is undefined 
      return obj?.find((elem) => elem.term_name == item);
    },

    loadCssFile() {
      const stylesheetId = `elementor-post-${this.pageId}-css`;

      if (document.getElementById(stylesheetId)) return;

      const domainElement =
        document.head && document.head.querySelector('[property="og:url"]');
      const domain = domainElement
        ? domainElement.content
        : "https://blyz.wyr.com.ua";

      const url = `${domain}/wp-content/uploads/elementor/css/post-${this.pageId}.css`;

      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        link.id = stylesheetId;
        link.onload = () => resolve();
        link.onerror = () =>
          reject(new Error(`Failed to load CSS file: ${url}`));
        document.head.appendChild(link);
      }).catch((reject) => {
        if (reject) {
          const stylesheetId = `elementor-post-${this.pageId}-css`;
          const stylesheetLink = document.getElementById(stylesheetId);

          if (stylesheetLink) {
            document.head.removeChild(stylesheetLink);
          }
        }
      });
    },
    loadJsFile(fileName) {
            if (!fileName || document.getElementById(fileName)) return;

            const domainElement = document.head && document.head.querySelector('[property="og:url"]');
            const domain = domainElement ? domainElement.content : "https://blyzenko.ua";

            const url = `${domain}/wp-content/themes/blyzenko-vue/${fileName}.js`;
            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = `js-${fileName}`;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds)
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        },
    modalForm(){
      this.$swal({
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            confirmButtonText: 'Надіслати',
            customClass: 'mainForm form-wrap cv-form',
            html: this.contactForm,
            focusConfirm: false,
            didOpen: () => {
              document.querySelector('.cv-form .wpcf7-file').addEventListener('input', (event) => {
                this.handleFileUpload(event);
              });

              document.querySelector('.cv-form #upload').addEventListener('click', () => {
                this.uploadFile();
               
              });

              const form = document.querySelector('.cv-form form');

              form.addEventListener('submit', (e) => {
                e.preventDefault();
                const formData = new FormData(form);
                const data = Object.fromEntries(formData.entries());
                this.sendContactFormForm(data)
                .then((result) => {
                  form.querySelector('.wpcf7-response-output').style.display = "block";
                  form.querySelector('.wpcf7-response-output').innerHTML = this.getSendFormConfirm ? this.getSendFormConfirm?.message : this.getSendFormError?.message
                  
                })
              });
            },
          })
    },
    handleFileUpload(event) {
      const files = event.target.files; // Access the file list
      if (files.length > 0) {

        const fileConatiner = document.querySelector('.cv-form .file-upload').getAttribute('data-limit')
        // const fileFormatList = document.querySelector('.cv-form .wpcf7-file').getAttribute('accept') 

        // const fineFormat = fileFormatList ? fileFormatList.split(',').find((element) => element == files[0].split('/')[1]) : 'empty'

        this.file = files[0]; // Store the first file in the data property

        if(files[0].size > fileConatiner ) {
          document.querySelector('#filename').innerHTML = "Файл занадто великий"
          return
        }

        // if(!fineFormat) {
        //   document.querySelector('#filename').innerHTML = "Файл недозволеного форматую Завантажте файлу форматі (jpeg, jpg, pdf, doc, dox)"
        //   return
        // }

        document.querySelector('#filename').innerHTML = files[0].name

        document.querySelector('#upload').innerHTML = "Завантажити"
        document.querySelector('#upload').classList.toggle('active')
        document.querySelector('#upload').style.opacity = 1
        
        document.querySelector('.cv-form .wpcf7-submit').disabled = true
        

      }
    },
    uploadFile(){
      document.querySelector('#upload').innerHTML = "Завантажую"
      document.querySelector('#upload').classList.toggle('active')

      this.fetchUploadFile(this.file)
      .then(()=>{
        if(this.getFormFileError) {
          document.querySelector('#filename').innerHTML = this.getFormFileError
          document.querySelector('#upload').style.opacity = 0
          
        } else {
          document.querySelector('#file-id').value =  this.getFormFileId 
          document.querySelector('#upload').style.opacity = 0
          document.querySelector('#filename').innerHTML = "файл завантажено"
          this.file = null
          document.querySelector('.wpcf7-file').value = ''
        }

        document.querySelector('.cv-form .wpcf7-submit').disabled = false

       
      })
      
    },
    vacancyName(vacancy) {
      if (!vacancy || !vacancy.vacancy_type || vacancy.vacancy_type.length === 0) {
        return 'Без назви';
      }

      return vacancy.vacancy_type[0].term_name || 'Без назви';
    },
    link(url) {

      const link = new URL(url);
      if (link.pathname) {
        return link.pathname;
      }

      return link;
    },
  },

};
</script>
<style lang="scss">
  @import "../../assets/scss/work-in-page.scss";
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
