import { mapGetters } from "vuex";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const gsapMixin = {
  data() {
    return {
      contentLoaded: false,
      iterate: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.contentLoaded = false; // Reset content loaded state
      this.runAnimationsWithInterval();
    },
  },
  computed: {
    ...mapGetters(["getPage"]),
  },
  mounted() {
    this.runAnimationsWithInterval();
  },
  updated() {

      this.runAnimationsWithInterval();
    
  },
  methods: {
    runAnimationsWithInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        if (this.getPage) {
          this.contentLoaded = true;
          this.iterate++;
          this.animateBgItems();
          this.animateVideo();
          this.animateHandWritingText();
          //this.bendingCorner();
          clearInterval(this.intervalId);
        }
      }, 300);
    },
    animateVideo() {
      const boxes = gsap.utils.toArray('.myVideo');
      if (boxes) {
        boxes.forEach((box) => {
          gsap.fromTo(
            box,
            { scale: 0.6, borderRadius: '50px', y: -150 },
            {
              scale: 1.1,
              borderRadius: '0px',
              y: 0,
              scrollTrigger: {
                trigger: box,
                start: 'top 100%',
                end: 'bottom 20%',
                scrub: 1,
                onComplete: () => box.classList.add('animated')
              },
            }
          );
        });
      }
    },
    animateBgItems() {
      let items = document.querySelectorAll(".animate-box-hover");
      if (!items || !items.length) return;

      items.forEach((item) => {
        if (!item.querySelector(".hover-bg")) {
          const animateBg = document.createElement("span");
          animateBg.className = "hover-bg";
          item.appendChild(animateBg);
        }

        const background = item.querySelector(".hover-bg");

        item.addEventListener("mouseenter", (e) => {
          item.classList.add('hover-item')
          const rect = item.getBoundingClientRect();
          const mouseX = e.clientX - rect.left;
          const mouseY = e.clientY - rect.top;
          gsap.fromTo(
            background,
            { width: 0, height: 0, top: mouseY, left: mouseX },
            {
              width: "200%",
              height: "200%",
              top: "50%",
              left: "50%",
              duration: 0.8,
            }
          );
        });

        item.addEventListener("mouseleave", (e) => {
          item.classList.remove('hover-item')
          const rect = item.getBoundingClientRect();
          const mouseX = e.clientX - rect.left;
          const mouseY = e.clientY - rect.top;
          gsap.to(background, {
            width: 0,
            height: 0,
            top: mouseY,
            left: mouseX,
            duration: 0.8,
          });
        });
      });
    },
    animateHandWritingText() {
      const textList = document.querySelectorAll('.color-text');
      if (textList.length) {
        textList.forEach((text) => {
          let textItem = text.querySelector('.elementor-widget-container > *') ? text.querySelector('.elementor-widget-container > *') : text;
          const chars = text.textContent.trim().split('');
          textItem.innerHTML = '';
          text.style.opacity = 1;

          chars.forEach((char, index) => {
            const span = document.createElement('span');
            span.textContent = char;
            span.classList.add('char');
            textItem.appendChild(span);

            const randomYOffset = Math.random() * 10 - 5; // Random y offset between -5 and 5

            gsap.from(span, {
              opacity: 0,
              y: randomYOffset + "px",
              delay: index * 0.05,
              ease: "power3.out",
              scrollTrigger: {
                trigger: textItem,
                start: "top 100%",
                end: "bottom 20%",
                toggleActions: "play none none none"
              }
            });
          });
        });
      }
    },
    bendingCorner(){
      const imageContainer = document.querySelector(".promo-paper");
      const page1 = imageContainer.querySelector('.page_one');
      const page2 = imageContainer.querySelector('.page_two');

      // Flip page animation on scroll
      page1.addEventListener('mouseenter', () => {
        gsap.to(page1, { 
          duration: 1, 
          rotationY: -180,
          transformOrigin: "bottom right",
          ease: "power2.inOut",
          onComplete: () => {
            page1.style.zIndex = 0;
            page2.style.zIndex = 2;
          }
        });
    
        gsap.to(page2, { 
          duration: 1, 
          rotationY: 0,
          transformOrigin: "bottom right",
          ease: "power2.inOut"
        });
      });
    
      // Reset page flip on mouse leave
      page2.addEventListener('mouseleave', () => {
        gsap.to(page1, { 
          duration: 1, 
          rotationY: 0,
          transformOrigin: "bottom right",
          ease: "power2.inOut",
          onComplete: () => {
            page1.style.zIndex = 2;
            page2.style.zIndex = 1;
          }
        });
    
        gsap.to(page2, { 
          duration: 1, 
          rotationY: -180,
          transformOrigin: "bottom right",
          ease: "power2.inOut"
        });
      });
    }
  },
}
