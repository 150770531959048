<template lang="">
    <article class="vacancy row container" v-if="vacancy">
        <div class="col">
            <section class="header-section">
                <h1 class="title" v-if="vacancy.title">{{ vacancy.title.rendered }}</h1>
                <div class="row base-info">
                    <div class="col">
                        <p class="title">Населений пункт</p>
                        <p class="text location" v-if="vacancy.location"><span v-for="(city, index) in vacancy.location" :key="city">{{city.term_name}}<span v-if="index == 0">,</span></span></p>
                    </div>
                    <div class="col">
                        <p class="title" >Зайнятість</p>
                        <p class="text" v-if="vacancy.work_data && !vacancy.work_data.length">Повна. Також готові взяти студента.</p>
                        <p class="text" v-if="vacancy.work_data && vacancy.work_data.length">{{vacancy.work_data[0].term_name}}</p>
                    </div>
                </div>
                <div class="description" v-html="vacancy.template"></div>
            </section>
        </div>
        <div class="col sitebar-wrap">
            <aside class="sitebar">
                <div class="contacts">
                    <p class="title">
                        Контактна особа
                    </p>
                    <p class="name">
                        Христина Головата
                    </p>
                    <div class="number-wrap">
                        <transition name="fade-show">
                            <button class="btn" v-if="!showPhone" @click=" showPhone = !showPhone">ПОКАЗАТИ НОМЕР</button>
                        </transition>
                        <transition name="fade-show">
                            <a href="tel:+0 800 201 800" class="phone" v-if="showPhone">0 800 201 800</a>
                        </transition>
                    </div>
                </div>

                <div class="form-wrap">
                    <p class="title">
                        Відгунутись на вакансію
                    </p>
                    <div class="cv-form" v-if="contactForm">
                        <div class="mainForm" v-html="contactForm"></div>
                        <!-- <form action="">
                        <p><input type="text" name="name" id="name" placeholder="Імʼя"></p>
                        <p><input type="tel" name="phone" id="phone" placeholder="Телефон"></p>
                        <p><input type="email" name="email" id="email" placeholder="E-mail"></p>
                        <p><textarea name="about" id="about" rows="4" placeholder="Пару слів про себе"></textarea></p>
                        <p class="file-upload"> 
                            <label>
                              <input type="file" id="file" name="file" accept="jpg, jpeg, pdf" :value="file"  @input="addFile">
                              <span class="filebtn">ПРИКРІПИТИ РЕЗЮМЕ</span>
                            </label>
                            <span id="filename" class="filename">{{fileName}}
                         </span></p>
                        <button type="submit" class="btn">НАДІСЛАТИ</button>
                        <p class="text">
                            Надсилаючи цю форму, я даю згоду на обробку моїх персональних даних відповідно до Політики конфіденційності.
                        </p>
                    </form> -->
                    </div>
                </div>
            </aside>
        </div>
    </article>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            showPhone: false,
            file: null,
            fileName: '',
            vacancy: null,
            contactForm: null,
        }
    },
    mounted() {
        const pageSlug = this.$route.params?.pageSlug;
        this.loadPageContent(pageSlug)
    },
    beforeUnmount() {
        const fileLink = document.getElementById('js-script');
        if (fileLink) {
            document.head.removeChild(fileLink);
        }
    },
    computed: {
        ...mapGetters([
            'getSingleVacancy',
            "getSettings",
            "getFormHtml",
            "getFormFileId",
            "getFormFileError",
            "getSendFormError",
            "getSendFormConfirm",
        ]),
    },
    methods: {
        ...mapActions([
            'fetchSingleVacancy',
            'fetchContactForm',
            "sendContactFormForm", 
            "fetchUploadFile",
        ]),
        loadPageContent(pageSlug){

            this.fetchSingleVacancy(pageSlug).then(() => {
                this.vacancy = this.getSingleVacancy
                this.loadForm()
            })

            return
        },
        loadForm(){
            this.fetchContactForm(this.vacancy.form_shortcode)
            .then(()=>{
                this.contactForm = this.getFormHtml ? this.getFormHtml.html : null

                setTimeout(() => {
                    if(this.contactForm) {
                        this.loadJsFile('script')
                        document.querySelector('.cv-form .wpcf7-file').addEventListener('input', (event) => {
                            this.handleFileUpload(event);
                        });

                        document.querySelector('.cv-form #upload').addEventListener('click', () => {
                            this.uploadFile();
                        });

                        const form = document.querySelector('.cv-form form');

                        form.querySelector('#vacncy-id').value = this.vacancy?.acf?.crm_vacancy_id

                        form.addEventListener('submit', (e) => {
                            e.preventDefault();
                            const formData = new FormData(form);
                            const data = Object.fromEntries(formData.entries());

                            this.sendContactFormForm(data)
                            .then(() => {
                                form.querySelector('.wpcf7-response-output').style.display = "block";
                                form.querySelector('.wpcf7-response-output').innerHTML = this.getSendFormConfirm ? this.getSendFormConfirm?.message : this.getSendFormError?.message
                            
                            })
                        });
                    }
                }, 300);
            })
        },
            loadJsFile(fileName) {
                if (!fileName || document.getElementById(`js-${fileName}`)) return;

                // Get domain from <meta> tag or set default
                const domainElement = document.head && document.head.querySelector('[property="og:url"]');
                const domain = domainElement ? domainElement.content : "https://blyzenko.ua";

                // Construct full URL for the JavaScript file
                const url = `${domain}/wp-content/themes/blyzenko-vue/${fileName}.js`;

            return new Promise((resolve, reject) => {
                const checkJQueryLoaded = setInterval(() => {
                    const jQueryScript = document.getElementById('jquery-core-js');
        
                    if (typeof jQuery !== "undefined" && jQueryScript) {
                        clearInterval(checkJQueryLoaded);

                        const link = document.createElement("script");
                        link.type = "text/javascript";
                        link.src = url;
                        link.id = `js-${fileName}`;
                        link.onload = () => resolve();
                        link.onerror = () => reject(new Error(`Failed to load js file: ${url}`));

                        // Insert the script after the jQuery script
                        jQueryScript.parentNode.insertBefore(link, jQueryScript.nextSibling);
                    }
                }, 100); // Check every 100ms

                // Optional timeout to reject the promise if jQuery never loads (e.g., after 10 seconds)
                setTimeout(() => {
                    clearInterval(checkJQueryLoaded);
                    reject(new Error('jQuery did not load within the expected time.'));
                }, 10000); // 10 seconds
            }).catch((error) => {
                
                const fileLink = document.getElementById(fileName);
                if (fileLink) {
                    document.head.removeChild(fileLink);
                }
            });
        },
        handleFileUpload(event) {
            const files = event.target.files; // Access the file list
            if (files.length > 0) {

                const fileConatiner = document.querySelector('.cv-form .file-upload').getAttribute('data-limit')
                // const fileFormatList = document.querySelector('.cv-form .wpcf7-file').getAttribute('accept') 

                // const fineFormat = fileFormatList ? fileFormatList.split(',').find((element) => element == files[0].split('/')[1]) : 'empty'

                this.file = files[0]; // Store the first file in the data property

                if(files[0].size > fileConatiner ) {
                document.querySelector('#filename').innerHTML = "Файл занадто великий"
                return
                }

                // if(!fineFormat) {
                //   document.querySelector('#filename').innerHTML = "Файл недозволеного форматую Завантажте файлу форматі (jpeg, jpg, pdf, doc, dox)"
                //   return
                // }

                document.querySelector('#filename').innerHTML = files[0].name

                document.querySelector('#upload').innerHTML = "Завантажити"
                document.querySelector('#upload').classList.toggle('active')
                document.querySelector('#upload').style.opacity = 1
                
                document.querySelector('.cv-form .wpcf7-submit').disabled = true
                

            }
            },
            uploadFile(){
            document.querySelector('#upload').innerHTML = "Завантажую"
            document.querySelector('#upload').classList.toggle('active')

            this.fetchUploadFile(this.file)
            .then(()=>{
                if(this.getFormFileError) {
                document.querySelector('#filename').innerHTML = this.getFormFileError
                document.querySelector('#upload').style.opacity = 0
                
                } else {
                document.querySelector('#file-id').value =  this.getFormFileId 
                document.querySelector('#upload').style.opacity = 0
                document.querySelector('#filename').innerHTML = "файл завантажено"
                this.file = null
                document.querySelector('.wpcf7-file').value = ''
                }

                document.querySelector('.cv-form .wpcf7-submit').disabled = false

            
            })
            
            },
    },
}
</script>
<style lang="scss">
    @import "../../assets/scss/single-work-in.scss";
</style>